.app {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: rgb(243, 242, 239);
}

[data-amplify-authenticator] {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 92%;
}

/*
A Hack to hide the Sign Up display
Works because the authenitcator tab only has 2 states (sign in and sign up)
and the former is configured to be initially active
*/
[data-amplify-authenticator] [data-state=inactive][role=tab] {
  display: none;
}