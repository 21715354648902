#ada-map-container {
  height: 100%;
}

.ada-map {
  height: 100%;
}

.ada-map-loader {
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgb(4,75,100);
}

.ada-map-loader-modal-open {
  position: absolute;
  z-index: 10;
  margin-left: auto;
  left: 0;
  margin-right: auto;
  right: 0;
  top: 5%;
  color: rgb(4,75,100);
}

.ada-map-filters-mobile-icon {
  position: absolute !important;
  top: 5% !important;
  right: 10% !important;
  height: 40px !important;
  width: 40px !important;
  background-color: white !important;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.3) !important;
  border-radius: 3px !important;
}