#ada-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ada-upload-loader {
  position: absolute !important;
  z-index: 10 !important;
  margin-left: auto !important;
  left: 0 !important;
  margin-right: auto !important;
  right: 0 !important;
  top: 30% !important;
  color: rgb(4,75,100);
}

#ada-image-upload {
  display: none;
}

.ada-upload-message-container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ada-upload-message {
  max-width: 80%;
  margin-top: 10px;
  color: darkgreen;
  font-weight: 700;
  overflow-wrap: normal;
  text-align: center;
}

.ada-upload-message.submit-error {
  color: darkred;
}

#ada-upload #upload-button {
  background-color: rgba(255,255,255);
  color: rgb(4,75,100);
  border-color: rgb(174, 179, 183);
}

#ada-upload #upload-button:hover {
  background-color: rgb(0,0,0,0.05);
}
