.ada-map-legend {
  position: absolute;
  top: 5%;
  right: 5%;
  min-height: 15%;
  min-width: 20%;
  padding: 10px 15px 10px 15px;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: space-between;
}

.ada-map-legend .filter-section:not(:first-child) {
  margin-top: 20px;
}

.ada-map-legend .filter-section-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
}

.ada-map-legend .form-check-input:checked {
  background-color: rgb(4,75,100);
  border-color: rgb(4,75,100);
}

.ada-map-legend .form-check-input:focus {
  border-color: rgb(4,75,100);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(4,75,100,.25);
}

.ada-map-legend .filter-option-checkbox {
  margin-right: 5%;
}

.ada-map-legend .filter-option-radio {
  margin-right: 5%;
  gap: 10px;
}
